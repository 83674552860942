<template>
    <div class="q-pa-md">
        <q-table
        title="Нарушения"
        :data="data"
        :columns="columns"
        row-key="number"
        no-data-label="Нет данных"
        no-results-label="Нет данных"
        :rows-per-page-options="[20]"
        table-header-class="bg-grey-2"
        >
             <template v-slot:top-right>
                <q-btn unelevated no-caps :color="showSearch ? 'grey-4' : 'grey-1'" text-color="grey-8" icon="search" label="Поиск" @click="showSearch =! showSearch"/>
                <q-btn unelevated no-caps color="grey-1" text-color="green-9" icon="add" label="Создать" to="/violations/create"/>
            </template>
             <template v-if="showSearch" v-slot:top-row>
                <q-tr>
                <q-td colspan="100%">
                    Тут будет поиск
                </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell="props">
                <q-th :props="props">
                    <strong>{{ props.col.label }}</strong>
                </q-th>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" style="cursor:pointer" @click="onRowClick(props.key)">
                    <q-td key="number" :props="props" >
                        {{props.row.number}}
                    </q-td>
                     <q-td key="date" :props="props">
                    {{props.row.date}}
                    </q-td>
                    <q-td key="author" :props="props">
                    {{props.row.author.label}}
                    </q-td>
                    <q-td key="violator" :props="props" :class="props.row.violator === null? 'text-red-4':''">
                    {{props.row.violator === null? 'Не установлен' : props.row.violator.label}}
                    </q-td>
                    <q-td key="type" :props="props">
                        <q-badge outline :color="typeColor(props.row.type)" :label="props.row.type.label" style="padding:4px 8px" class="text-capitalize"/>
                    </q-td>
                    <q-td key="case" :props="props" @click.stop="onCaseClick(props)">
                        <q-btn v-if="showCase(props)" rounded :color="caseColor(props.row.case)" size="sm" :label="props.row.case === null ? 'Создать': props.row.case.number" @click.stop="onCaseClick(props)"/>
                        <span v-else></span>
                    </q-td>
                    <q-td key="status" :props="props">
                        <!-- {{props.row.status}} -->
                         <q-badge :color="statusColor(props.row.status)" :label="props.row.status.label" style="padding:5px 10px"/>
                        <!-- <q-btn-dropdown outline rounded size="sm" :color="statusColor(props.key)" :label="props.row.status">
                            <q-list>
                                <q-item v-for="(stat, index) in stats" :key="index" clickable v-close-popup @click="onChangeStatus(props.key, stat)">
                                    <q-item-section>
                                        <q-item-label>{{stat}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-btn-dropdown> -->
                        
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script>
export default {
    data () {
        return {
            editStatus: false,
            columns: [
                { name: 'number',required: true,label: '#',align: 'left',field: 'number', sortable: true},
                { name: 'date', required: true, label: 'Дата', align: 'left', field: 'date', sortable: true},
                { name: 'author', align: 'left', label: 'Автор', field: 'author', sortable: true },
                { name: 'violator', align: 'left', label: 'Нарушитель', field: 'violator', sortable: true },
                { name: 'type', align: 'center', label: 'Тип нарушения', field: 'type', sortable: true },
                { name: 'case',  align: 'left', label: 'Дело №', field: 'case' },
                { name: 'status',  align: 'left', label: 'Статус', field: 'status', sortable: true},
            ],
            stats: ['Новое', 'В деле', 'Архивное'],
            showSearch:false
        }
    },
    mounted(){
        this.$store.dispatch('violations/fetchData')
    },
    computed:{
        data(){
            return this.$store.state.violations.data || []
        },
        typeColor(){
            return val => val.id === 1 ? 'secondary' : 'red-4'
        },
        caseColor(){
            return val => val === null ? 'secondary':'primary'
        },
        
        statusColor(){
            return val => val.id === 1 ? 'secondary': val.id === 2 ? 'primary' : 'warning'
        }

    },
    methods: {
        onRowClick(row){
            this.$router.push('/violations/'+row)
        },
        onChangeStatus(row, status){
            this.$store.commit('violations/SET_STATUS', {'row':parseInt(row)-1, 'status':status})
        },
        onCaseClick(props){
            if(props.row.case === null && props.row.status.id != 1){
                this.$router.push('/violations/'+props.key)
                return 
            }
            if(props.row.case !== null){
                this.$router.push('/cases/'+props.row.case.id)
                return
            }
            this.$store.commit('cases/SET_CREATE_ON_VIOLATION',props.row)
            this.$router.push('/cases/create')
        },
        showCase(props){
            if(props.row.status.id == 3 && props.row.case == null){
                return false
            }
            return true
        },
    },
    
}
</script>

<style scoped>
    /* td:first-child{
        background-color: #f5f5dc
    } */
</style>