<template>
    <div>
        <table-violations/>
    </div>
</template>

<script>
import TableViolations from '@/components/violations/table.vue'
export default {
  components: { TableViolations },
    data () {
        return {
           
        }
    }
    
}
</script>